import "./style.scss";

import {
  DownloadOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Button, Card, DatePicker, Form, Row, Col, notification, Select, Layout } from "antd";
import Alert, { AlertProps } from "antd/es/alert/Alert";
import dayjs, { Dayjs } from "dayjs";
import { NotificationPlacement } from "antd/es/notification/interface";
import { downloadSpeedLimitReport, downloadTaskStatusReport } from "./Slice";
const { Content } = Layout;
const { Option } = Select;

const checkDate = (date: Dayjs) => {
  return (
    date &&
    (date.isAfter(dayjs().subtract(1, "day").endOf("day")) ||
      date.isBefore(dayjs().subtract(30, "day").startOf("day")))
  );
};
const alertInfo: AlertProps = {
  type: "info",
  icon: <InfoCircleOutlined />,
  message: null,
  description: "ข้อมูลรายงานสามารถดาวน์โหลดย้อนหลังได้ 30 วันเท่านั้น",
};
const alertError: AlertProps = {
  type: "error",
  icon: <CloseCircleOutlined />,
  message: "เกิดข้อผิดพลาด",
  description: "ไม่สามารถดาวน์โหลดรายงานได้ กรุณาลองใหม่อีกครั้ง",
};

export default function TaskStatusReport() {
  const [alertConfig, setAlertConfig] = useState(alertInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolderNoti] = notification.useNotification();
  const [reportType, setReportType] = useState<"conform_delivery" | "speed_limit" | null>(null);

  const onPlanArrivalChange = () => {
    setAlertConfig(alertInfo);
  };

  const onDownload = (values: any) => {
    setIsLoading(true);

    if (reportType === "conform_delivery") {
      downloadTaskStatusReport(values.planned_arrival_date.format("DD-MM-YYYY"))
        .then((res) => {
          createFile(res);
        })
        .catch(() => {
          setAlertConfig(alertError);
          setIsLoading(false);
        });
    } else {
      downloadSpeedLimitReport(values.planned_arrival_date.format("DD-MM-YYYY"))
        .then((res) => {
          createFile(res);
        })
        .catch(() => {
          setAlertConfig(alertError);
          setIsLoading(false);
        });
    }
  };

  const createFile = (res: any) => {
    const filename = res.headers["content-disposition"].split(";")[1].split("=")[1];
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = URL.createObjectURL(res.data);
    link.setAttribute("download", decodeURI(filename));
    link.click();
    setAlertConfig(alertInfo);
    openNotiSuccess("topRight");
    setIsLoading(false);
  };

  const openNotiSuccess = (placement: NotificationPlacement) => {
    api.success({
      message: "ดาวน์โหลดสำเร็จ !",
      description: "การดาวน์โหลดรายงานเสร็จสิ้น",
      className: "task-status-report noti-success",
      icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
      placement,
    });
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <Content className="management-content task-status-report">
      <p className="title">Report</p>
      <Row>
        <Col span={24} xl={12}>
          <Card>
            <Form
              layout="vertical"
              onFinish={onDownload}
              initialValues={{
                planned_arrival_date: dayjs().subtract(1, "day"),
              }}
            >
              <Row>
                <Col span={24} xl={9}>
                  <Form.Item
                    {...formItemLayout}
                    label="ประเภทของรายงาน"
                    name="report_type"
                    rules={[{ required: true, message: "กรุณาเลือก" }]}
                  >
                    <Select
                      onChange={(type) => {
                        setReportType(type);
                      }}
                      className="report-type-select"
                      placeholder="กรุณาเลือกประเภท"
                      data-testid="report-type-select"
                      style={{ width: "100%", padding: "0 5px" }}
                    >
                      <Option value="conform_delivery">รายงานยืนยันจัดส่ง</Option>
                      <Option value="speed_limit">รายงาน Speed limit</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} xl={9}>
                  <Form.Item
                    {...formItemLayout}
                    label="วัน/เดือน/ปี รายงาน"
                    name="planned_arrival_date"
                    rules={[{ required: true, message: "กรุณาเลือกวันที่" }]}
                  >
                    <DatePicker
                      disabledDate={checkDate}
                      format={"DD-MM-YYYY"}
                      onChange={onPlanArrivalChange}
                      data-testid="planned-arrival-date"
                      allowClear={false}
                      style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} xl={6}>
                  <Form.Item label={<div className="button-label">&nbsp;</div>} {...formItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<DownloadOutlined />}
                      loading={isLoading}
                      disabled={isLoading}
                      style={{ margin: "0 5px" }}
                    >
                      ดาวน์โหลด
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Alert
              type={alertConfig.type}
              icon={alertConfig.icon}
              message={alertConfig.message}
              description={alertConfig.description}
              showIcon
            />
          </Card>
        </Col>
      </Row>
      {contextHolderNoti}
    </Content>
  );
}
