import "./style.scss";

import { Button, Form, Input, Progress, message } from "antd";
import { CheckCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { confirmPasswordReset } from "firebase/auth";
import { getAuth } from "../../shared/api/FirebaseAuth";
import { useForm } from "antd/lib/form/Form";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export default function Login() {
  const [form] = useForm();
  const [passwordStrength, setPasswordStrength] = useState<number>(0);
  const [validateCase16, setValidateCase16] = useState(false);
  const [validateUpper, setValidateUpper] = useState(false);
  const [validateLower, setValidateLower] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [validateSpecial, setValidateSpecial] = useState(false);
  const [loadingConfirmButton, setLoadingConfirmButton] = useState(false);
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode") || "";
  const navigate = useNavigate();

  useEffect(() => {
    if (oobCode === "") {
      navigate("/");
    }
  }, []);

  const onFinish = async (values: FormValues) => {
    if (values.password === values.confirmPassword) {
      try {
        await confirmPasswordReset(getAuth(), oobCode, values.confirmPassword);
        setLoadingConfirmButton(false);
        message.success("เปลี่ยนรหัสผ่านสำเร็จ");
        navigate("/");
      } catch (error) {
        message.error("เปลี่ยนรหัสผ่านไม่สำเร็จ");
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const PASSWORD_STRENGTH_REGEXPS = [/[a-z]/, /[A-Z]/, /[0-9]/, /[!@#$%&*]/];

  const getPasswordStrength = (value: string): number => {
    const validations = [false, false, false, false, false];

    for (let i = 0; i < PASSWORD_STRENGTH_REGEXPS.length; i++) {
      validations[i] = PASSWORD_STRENGTH_REGEXPS[i].test(value);
    }

    const lengthValidation = value.length >= 16;
    validations[validations.length - 1] = lengthValidation;

    const strength = validations.reduce((total, validation) => total + (validation ? 20 : 0), 0);

    [setValidateLower, setValidateUpper, setValidateNumber, setValidateSpecial, setValidateCase16].forEach(
      (setter, i) => setter(validations[i]),
    );

    return strength;
  };

  const passwordValidator = () => {
    const password = form.getFieldValue("password");
    const strength = getPasswordStrength(password);
    setPasswordStrength(strength);

    if (password && strength === 100) {
      return Promise.resolve();
    }

    return Promise.reject("กรุณากรอก Password ให้ถูกต้อง");
  };

  const confirmPasswordValidator = () => {
    const password = form.getFieldValue("password");
    const confirmPassword = form.getFieldValue("confirmPassword");
    if (password === confirmPassword) {
      return Promise.resolve();
    }
    return Promise.reject("กรุณากรอก Password ให้ตรงกัน");
  };

  const hintList = [
    {
      text: "อักขระไม่ต่ำกว่า 16 ตัวอักษร",
      validate: validateCase16,
    },
    {
      text: "ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัว",
      validate: validateUpper,
    },
    {
      text: "ตัวพิมพ์เล็กอย่างน้อย 1 ตัว",
      validate: validateLower,
    },
    {
      text: "ตัวเลขอย่างน้อย 1 ตัว",
      validate: validateNumber,
    },
    {
      text: "อักขระพิเศษอย่างน้อย 1 ตัว",
      validate: validateSpecial,
    },
  ];

  return (
    <div className="wrap-login-page">
      <div className="wrap-form form-reset-pass">
        {passwordStrength > 0 && (
          <Progress
            strokeWidth={4}
            showInfo={false}
            strokeLinecap="butt"
            strokeColor={passwordStrength < 100 ? "red" : "#52c41a"}
            percent={passwordStrength}
          />
        )}
        <Form
          style={{ padding: "40px" }}
          form={form}
          name="login"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            label="New Password"
            rules={[
              { required: true, message: "Please input your password!" },
              { validator: passwordValidator },
            ]}
          >
            <Input.Password
              onChange={(e) => {
                if (e.target.value === "") {
                  form.setFieldsValue({
                    confirmPassword: undefined,
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            rules={[
              { required: true, message: "กรุณากรอก Confirm Password" },
              { validator: confirmPasswordValidator },
            ]}
          >
            <Input.Password disabled={passwordStrength < 100} />
          </Form.Item>
          <div className="wrap-password-hint">
            <ul>
              {hintList.map((x, i) => {
                return (
                  <li key={i} style={{ color: x.validate ? "#52c41a" : "#262626" }}>
                    <span>{x.validate ? <CheckCircleFilled rev="" /> : <InfoCircleFilled rev="" />}</span>
                    <span>{x.text}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="wrap-login-action">
            <Button
              style={{ marginRight: 12 }}
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loadingConfirmButton}
              type="primary"
              htmlType="submit"
              onClick={() => {
                setLoadingConfirmButton(true);
              }}
            >
              OK
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
