import { CheckCircleOutlined } from "@ant-design/icons";
import DesignSystemTheme from "../../shared/component/DesignSystemTheme";
import { Card, Col, Layout, Row, Upload, UploadFile, UploadProps, notification } from "antd";
import DraggerFileComponent from "../../shared/component/DraggerFileComponent";
import { Form, FormDs, SelectDs } from "design-system";
import { dcList, dcLists } from "../../utils/DClists";
import React, { useEffect, useRef, useState } from "react";
import DeliveryOrderTable from "./components/DeliveryOrderTable";
import DeliveryOrderService from "./services/delivery-order-service";
import "./style.scss";
import { Cell, ErrorHeader, Eta } from "./models/delivery-order.model";
import TooltipComponent from "../../shared/component/TooltipComponent";
import LocalstorageService from "../../shared/service/Localstorage.service";

const { Content } = Layout;

const DeliveryOrder = () => {
  const role = LocalstorageService.getCurrentRole();
  const [form] = Form.useForm();
  const [fileUploaded, setFileUploaded] = useState<UploadFile[]>([]);
  const [etaList, setEtaList] = useState<Eta[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessageFileUpload, setErrorMessageFileUpload] = useState<{
    title: string;
    description: string;
  } | null>(null);
  const [notificationComponent, contextHolderNoti] = notification.useNotification();
  const dcCodeList = useRef<string[]>(dcLists().map((dc) => dc.value));
  const renderCount = useRef(0);

  useEffect(() => {
    if (dcCodeList.current.length > 0) {
      getDataEta();
    } else {
      dcList.subscribe((dcs) => {
        if (dcs && renderCount.current === 0) {
          renderCount.current = 1;
          dcCodeList.current = dcs.map((dc) => dc.value);
          getDataEta();
        }
      });
    }

    return () => {
      renderCount.current = 0;
      notification.destroy();
    };
  }, []);

  const getDataEta = () => {
    setLoading(true);
    DeliveryOrderService.getEtas().then(
      (res) => {
        const etasListBindingData = mapEtasList(dcCodeList.current, res);
        setEtaList(etasListBindingData);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      },
    );
  };

  const mapEtasList = (dcCodeList: string[], etasList: Record<string, Partial<Eta>>): Eta[] =>
    dcCodeList.map((dcCode) => ({
      ...createEmptyEtaFile(dcCode),
      ...etasList[dcCode],
    }));

  const createEmptyEtaFile = (dcCode: string): Eta => ({
    dc_code: dcCode,
    uploaded_date: "",
    filename: "",
    uploaded_by: "",
    uploaded_time: "",
    total: 0,
    completed_count: 0,
    file_path: "",
    sent_notification_by: "",
    sent_notification_time: "",
    record_uploaded: 0,
  });

  const props: UploadProps = {
    name: "file",
    multiple: false,
    customRequest: ({ onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 500);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setFileUploaded(info.fileList);
        setErrorMessageFileUpload(null);
      }
    },
    beforeUpload: (file: any) => {
      const isXlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isXlsx) {
        setErrorMessageFileUpload({
          title: "Format ของ File ไม่ถูกต้อง",
          description:
            "ไม่สามารถ Upload ได้เนื่องจาก Format ไม่ถูกต้อง กรุณา Upload เฉพาะไฟล์ .xlsx เท่านั้น",
        });
      }
      return isXlsx || Upload.LIST_IGNORE;
    },
    onRemove(file: UploadFile) {
      const index = fileUploaded.indexOf(file);
      if (index > -1) {
        const recentList = fileUploaded.filter((_, fidx) => fidx !== index);
        setFileUploaded(recentList);
      }
    },
  };

  const onSubmit = () => {
    form.validateFields().then(() => {
      const dc_name = form.getFieldValue("dc_name");
      const fileName = fileUploaded[0].originFileObj?.name || "";

      if (fileName.toLocaleLowerCase().indexOf(dc_name.toLocaleLowerCase()) === -1) {
        setErrorMessageFileUpload({
          title: `ชื่อ File ไม่สอดคล้องกับศูนย์กระจายสินค้าที่เลือก `,
          description: `ชื่อ File ต้องมีชื่อศูนย์กระจายสินค้า ${dc_name} เป็นส่วนหนึ่งของชื่อ File`,
        });
        return false;
      }

      notification.destroy();
      setLoading(true);
      DeliveryOrderService.uploadEta(dc_name, fileUploaded[0].originFileObj as File).then(
        () => {
          setFileUploaded([]);
          setLoading(false);
          openNotiSuccess("อัพโหลดไฟล์สำเร็จ", fileName, dc_name);
          form.resetFields();
          getDataEta();
        },
        (err) => {
          const cellErrorResponse = err.response.data.cells;
          const errorHeaderResponse = err.response.data;

          if (cellErrorResponse) {
            openNotificationErrorCell("อัพโหลดไฟล์ไม่สำเร็จ", cellErrorResponse);
          } else {
            openNotificationErrorHeader("อัพโหลดไฟล์ไม่สำเร็จ", errorHeaderResponse);
          }
          form.resetFields();
          setFileUploaded([]);
          setLoading(false);
        },
      );
    });
  };

  const renderInvalidHeader = (data: ErrorHeader) => {
    type ErrorMessage = {
      [key: string]: string;
    };
    const errorMessge: ErrorMessage = {
      invalid_file_header_column_length: "จำนวน Column ไม่ถูกต้อง",
      invalid_file_header_column_name: "ชื่อ Column ไม่ถูกต้อง",
    };
    return (
      <div>
        <div>{errorMessge[data.code]}</div>
        <li>{data.message}</li>
      </div>
    );
  };

  const renderInvalidCell = (cell: Cell) => {
    type ErrorMessage = {
      [key: string]: string;
    };
    const errorMessge: ErrorMessage = {
      invalid_mobile_phone: "รูปแบบโทรศัพท์ไม่ถูกต้อง",
      invalid_truck_plate_number: "รูปแบบป้ายทะเบียนไม่ถูกต้อง",
      invalid_planned_load_date: "ข้อมูลวันที่ Planned Load Date ไม่ถูกต้อง",
      invalid_planned_arrival_date: "ข้อมูลวันที่ Planned Arrival Date ไม่ถูกต้อง",
      invalid_do_type: "ข้อมูล DO Type ไม่ถูกต้อง",
      not_found_in_store_master: "ไม่มีข้อมูลสาขานี้ในระบบ",
      invalid_truck_type: "ข้อมูลประเภทรถไม่ถูกต้อง",
      inconsistent_stop: "มีข้อมูล stop ไม่ถูกต้อง",
      inconsistent_truckplate: "มี load ซ้ำกัน",
      inconsistent_store_number: "มี load หรือ stop ซ้ำกัน",
      invalid_planned_load_time: "รูปแบบเวลาไม่ถูกต้อง",
    };

    if (cell.code === "inconsistent_stop" || cell.code === "inconsistent_store_number") {
      return (
        <li>
          หมายเลขสโตร์ {cell.data} {errorMessge[cell.code]}
        </li>
      );
    } else if (cell.code === "inconsistent_truckplate") {
      return (
        <li>
          ป้ายทะเบียน {cell.data} {errorMessge[cell.code]}
        </li>
      );
    } else {
      return (
        <li>
          {errorMessge[cell.code]} แถวที่ {cell.row} คอลัมน์ {cell.col}
        </li>
      );
    }
  };

  const openNotificationErrorCell = (message: string, cells: any) => {
    notification.destroy();
    const cellError = cells?.map((cell: Cell, index: number) => (
      <React.Fragment key={index}>{renderInvalidCell(cell)}</React.Fragment>
    ));
    notification.open({
      type: "error",
      className: "upload-eta-notification noti-error",
      message: message,
      description: cellError,
      placement: "top",
      duration: 0,
    });
  };

  const openNotificationErrorHeader = (message: string, data: ErrorHeader) => {
    notification.destroy();
    const cellError = renderInvalidHeader(data);
    notification.open({
      type: "error",
      className: "upload-eta-notification noti-error",
      message: message,
      description: cellError,
      placement: "top",
      duration: 0,
    });
  };

  const openNotiSuccess = (message: string, fileName: string, dcName: string) => {
    notificationComponent.success({
      message: <h4 style={{ fontSize: "16px" }}>{message}</h4>,
      description: `Upload file ${fileName} ศูนย์กระจายสินค้า ${dcName} สำเร็จ`,
      className: "success-notification",
      icon: <CheckCircleOutlined style={{ color: "#41A447" }} />,
      placement: "topRight",
    });
  };

  return (
    <DesignSystemTheme>
      <Content className="delivery-order-content">
        <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
          <h1>Delivery Order</h1>
          <TooltipComponent title="ส่วนการอัปโหลดรายการจัดส่งสินค้า" />
        </div>
        <Row gutter={[16, 16]}>
          {role && !role.includes("planner") && (
            <Col span={24}>
              <Card>
                <FormDs form={form}>
                  <h3>Upload File</h3>
                  <Form.Item
                    name="dc_name"
                    label="ศูนย์กระจายสินค้า"
                    rules={[{ required: true, message: "กรุณาเลือกศูนย์กระจายสินค้า" }]}
                  >
                    <SelectDs
                      placeholder="กรุณาเลือกศูนย์กระจายสินค้า"
                      options={dcCodeList.current.map((dcCode) => ({
                        label: dcCode,
                        value: dcCode,
                      }))}
                      style={{ width: "258px" }}
                      data-test-id="select_dc_name"
                      onChange={() => {
                        setErrorMessageFileUpload(null);
                      }}
                    />
                  </Form.Item>
                  <DraggerFileComponent
                    draggerProps={props}
                    contentText="Click or drag file to this area for upload"
                    descriptionText="File support .xlsx only"
                    fileUpload={fileUploaded}
                    fileTemplateHref="/files_download/ETA_Template_File_v7.0.xlsx"
                    onSubmit={onSubmit}
                    errorMessage={errorMessageFileUpload}
                    isLoading={loading}
                    data-test-id="select_file_eta"
                  />
                </FormDs>
              </Card>
            </Col>
          )}
          <Col span={24}>
            <Card>
              <DeliveryOrderTable dataSource={etaList} isLoading={loading} onChange={getDataEta} />
            </Card>
          </Col>
        </Row>
      </Content>
      {contextHolderNoti}
    </DesignSystemTheme>
  );
};

export default DeliveryOrder;
